import * as request from '@/api'
import { baseUrl } from '@/config/api'
import { getMe } from '@/js/getMe.js'
import router from '@/router'
const login = async(data) => {
    try {
        const res = await request.post(baseUrl+ '/api/Auth/Login', data)
        const token =  `Bearer ${res.token}`
        localStorage.setItem('tk-scgp-covid', token)
        const resGetMe = await getMe()
        if (resGetMe) {
            router.push({ name: 'main' })
        }
    } catch (error) {
        return false
    }
}
export {
    login
}