<template >
    <div class="modal-login-ad">
      <Loading :isLoading="isLoading"></Loading>
     <vue-final-modal
        :click-to-close="false"
        v-model="fncShowModal"
          classes="modal-container"
          content-class="modal-content"
        >
        <span class="modal__title title-modal" >
            <img src="@/assets/scgplogo.png" width="150" height="50" style="height: 152px; margin-bottom: -10px;">
        </span>
        <div class="modal__content">
          <form @submit="submitLogin">
          <div class="form-group">
                <input class="form-control" placeholder="username" v-model="formData.username" required>
            </div>
            <div class="form-group">
                <input class="form-control" placeholder="password" type="password" v-model="formData.password" required>
            </div>
            <div class="form-group operation-modal ">
                <button class="btn btn-login" style="width:150px; margin-right:10px; margin-bottom:10px;" type="submit" ><i class="bi bi-box-arrow-in-left" style="margin-right:10px"></i>LOGIN</button>
                <button class="btn btn-danger " style="width:150px margin-right:10px; margin-bottom:10px;" type="button" @click="closeModal" ><i class="bi bi-x" style="margin-right:10px"></i>CANCEL</button>
            </div>
          </form>
        </div>
      </vue-final-modal>
    </div>
</template>
<script>

import { defineAsyncComponent } from 'vue'
const Loading = defineAsyncComponent(() => import('@/components/loading-overlay'))
import { login } from '@/js/login.js'
export default {
    props: {
        showModal: {
            type: Boolean
        },
    },
    components: {
        Loading
    },
    computed: {
        fncShowModal () {
            return this.showModal ? true : false
        }
    },
    data() {
        return {
            isLoading: false,
            formData: {
                username: '',
                password: '',
            }
        }
    },
    methods: {
        closeModal () {
            this.$emit('closeModal', false)
        },
        async submitLogin (e) {
            e.preventDefault();
            this.isLoading = true
            this.formData.username = this.formData.username.replace('@scg.com', '')
            try {
                const data = {
                    email: this.formData.username + '@scg.com',
                    password: this.formData.password,
                    isAdUser: true
                }
                await login(data)
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        }
    }
    
}
</script>

<style lang="scss" scoped>
.modal-login-ad {
    .form-group {
        margin-bottom: 2rem
    }
    .btn-login {
        background-color: #083C5D;
        color: white;
    }
  .title-modal {
    text-align: center;
    padding-bottom: 20px
  }
    .operation-modal {
        padding-top:10px;
        text-align: center;
    }
    ::v-deep(.modal-container) {
    display: flex;
    justify-content: center;
    align-items: center;
    }
    ::v-deep(.modal-content) {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding: 1rem;
    max-width:  400px !important;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
    }
    .modal__title {
    font-size: 1.5rem;
    font-weight: 700;
    }
    .dark-mode div ::v-deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
    }
}
</style>